<template>
  <div>
    <base-card :loading="cardLoading">
      <div v-show="branchList == ''" v-if="!cardLoading">
        <v-card-text class="text-center">
          <div class="pa-16">
            <v-btn
              class="mx-2"
              fab
              x-large
              disabled
              depressed
              style="font-size: 399px !important"
            >
              <v-icon x-large> mdi-format-list-checkbox </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div class="my-2">
              <span class="text-subtitle-1">No branch Modifier Found</span>
            </div>

            <v-spacer></v-spacer>
          </div>
        </v-card-text>
      </div>
      <div v-show="branchList != ''">
        <v-card-title>
          <v-row>
            <v-col cols="12" lg="8">Branch Modifier</v-col>
            <v-col cols="12" lg="4"
              ><v-text-field
                class="mt-n5"
                v-model="searchBranch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="branchList"
            item-key="id"
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            @item-expanded="onExpand"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            :search="searchBranch"
            hide-default-footer
          >
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="modHeader"
                  :items="modifierList"
                  hide-default-footer
                  hide-default-header
                  @click:row="handleClick"
                  :loading="tableLoading"
                >
                  <template v-slot:item.name="{ item }">
                    <span class="font-weight-bold">{{ item.name }}</span> <br />
                    <span
                      v-for="(mod, index) in item.item"
                      :item="mod"
                      :index="index"
                      :key="mod.mod_item_id"
                      >{{ mod.name
                      }}<span v-if="index < item.item.length - 1">, </span>
                    </span>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon small class="mr-2"> mdi-pencil </v-icon>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
          <div class="text-center py-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>
      </div>
    </base-card>
    <v-dialog v-model="dialog" eager persistent width="400">
      <base-card>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="mb-5">
              <span class="text-h6">Edit Modifier</span>
            </div>
            <v-text-field
              prepend-icon="mdi-alphabetical-variant"
              label="Modifier Group Name"
              v-model="mName"
              :rules="[(v) => !!v || 'Modifier group name is required']"
              readonly
            ></v-text-field>

            <draggable
              :list="list"
              @start="drag = true"
              @end="drag = false"
              class="mb-n1"
            >
              <v-list v-for="(element, idx) in list" :key="element.sequence">
                <v-row class="mb-n5">
                  <v-col cols="1"
                    ><v-icon class="row-pointer">mdi-menu</v-icon></v-col
                  >

                  <v-col cols="6">
                    <v-text-field
                      v-model="element.name"
                      class="mt-n4"
                      label="Option Name"
                      :rules="[(v) => !!v || 'Option name is required']"
                      readonly
                    ></v-text-field
                  ></v-col>
                  <v-col cols="3"
                    ><v-text-field
                      v-model="element.price"
                      label="Price"
                      class="mt-n4"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => v >= 0 || 'Price should not be negative',
                      ]"
                      type="number"
                    ></v-text-field
                  ></v-col>

                  <v-col cols="1">
                    <v-switch
                      class="mt-n1"
                      true-value="1"
                      false-value="0"
                      v-model="element.status"
                    ></v-switch
                  ></v-col>
                </v-row>
              </v-list>
            </draggable>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" @click="closeDialog()" text> close </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAction()" text> save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deleteTable()" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },

  data: () => ({
    expanded: [],
    domain: BASEURL,
    syncDomain: BASESYNCURL,
    snackbar: false,
    text: "",
    snackbarColor: "",
    timeout: 1900,
    companyID: "",
    branchList: [],
    modifierList: [],
    cardLoading: true,
    tableLoading: true,
    dialog: false,
    searchBranch: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dialogDelete: false,
    mName: "",
    valid: "",
    list: [],
    headers: [
      {
        text: "Branch",
        align: "start",
        value: "name",
        sortable: false,
      },
      {
        text: "Total Modifier",
        align: "center",
        value: "modifier_num",
        sortable: false,
      },
      { text: "", value: "data-table-expand" },
    ],
    modHeader: [
      {
        text: "Modifier Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Action",
        align: "end",
        value: "action",
      },
    ],
  }),

  computed: {},

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getBranchAndModNum();
  },
  methods: {
    getBranchAndModNum() {
      const params = new URLSearchParams();
      params.append("getBranchAndModNum", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/modifier/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.modifier;
            this.cardLoading = false;
          } else {
            this.branchList = [];
            this.cardLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onExpand({ item, value }) {
      if (value) {
        const params = new URLSearchParams();
        params.append("getAllModifierByBranch", "done");
        params.append("branch_id", item.id);

        axios({
          method: "post",
          url: this.domain + "/modifier/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.modifierList = response.data.modifier;
              console.log(this.modifierList);
              this.tableLoading = false;
            } else {
              this.modifierList = [];
              this.tableLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    handleClick(item) {
      this.mName = item.name;
      this.list = item.item;
      this.selectModifier = item.id;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBranchForNotification(branch_link_modifier_id) {
      const params = new URLSearchParams();
      params.append("getModifierLinkBranch", "done");
      params.append("branch_link_modifier_id", branch_link_modifier_id);
      axios({
        method: "post",
        url: this.domain + "/modifier/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var branchList = response.data.modifier;
            for (let i = 0; i < branchList.length; i++) {
              this.sendNotication(branchList[i].branch_id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      if (this.$refs.form.validate()) {
        const params = new URLSearchParams();
        params.append("editBranchModifier", "done");
        params.append("list", JSON.stringify(this.list));
        axios({
          method: "post",
          url: this.domain + "/modifier/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully edit";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.getBranchAndModNum();
              this.getBranchForNotification(this.list[0].branch_link_modifier_id)
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.text = "Please fill in the price";
        this.snackbar = true;
        this.snackbarColor = "error";
      }
    },
  },
};
</script>
<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>