<template>
  <v-row>
    <v-col cols="12">
        <branch-modifier-card> </branch-modifier-card> 
    </v-col>
  </v-row>
</template>
<script>
import BranchModifierCard from "@/components/product/BranchModifierCard";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Branch Modifier",
  },

  components: {
    BranchModifierCard
  },

  data() {
    return {
    };
  },
};
</script>